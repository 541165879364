import axios from 'axios';
import Api from '../../common/Api';
import ApiUrl from '../../common/ApiUrl';
import { NOTIFICATION_SUCCESS, NOTIFICATION_FAILURE } from '../actionType'

export const getNotificationsList = (StudentId) => async dispatch => {
    try {
        const response = await Api.doFetch("GET", {}, ApiUrl.GET_STUDENT_NOTIFICATION + '?student=' + `${StudentId}`)
        // const { data } = await axios.get(process.env.REACT_APP_BASE_URL + `student/get-notification/?student=${StudentId}`)
        if (response.status === true) {
            dispatch({
                type: NOTIFICATION_SUCCESS,
                payload: response.data,
            })
        } else {
            // Stop alert
            alert(response.message)
        }
    } catch (error) {
        dispatch({
            type: NOTIFICATION_FAILURE,
            payload: error
        })
    }
}